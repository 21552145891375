import { MIXPANEL_MASTER_API_TOKEN, TIKTOK_PIXEL_ID } from "../foundation/constants";
import Cookies from "../foundation/cookies";
import { getAppVersion, getAppClient } from "../foundation/utils/ua-utils";
import { shouldTrackUser } from "../foundation/utils/user";

/* eslint-disable */
// Mixpanel
(function(f,b){if(!b.__SV){var a,e,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.track_charge people.clear_charges people.delete_user time_event opt_in_tracking opt_out_tracking get_distinct_id".split(" ");for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=f.createElement("script");a.type="text/javascript";a.async=!0;a.src="//cdn.zeplin.io/assets/webapp/mixpanel/zeplin-mixpanel-2-latest.min.js";e=f.getElementsByTagName("script")[0];e.parentNode.insertBefore(a,e)}})(document,window.mixpanel||[]);

const { mixpanelProxy } = Zeplin.apiConfig.url;
mixpanel.init(MIXPANEL_MASTER_API_TOKEN, { api_host: mixpanelProxy }, "master");
/* eslint-enable */

// The preference about if the user opted out or not is being kept as cookie. And it might be deleted.
// With the following check we're making sure that we don't track events if the user opted out.
if (Zeplin.user && !shouldTrackUser(Zeplin.user)) {
    mixpanel.master.opt_out_tracking();
}

// Following unregisters are also being applied in app.js.
// The reason we're doing this in here too is that app.js is not being executed in legacy parts of the app.
// Once we complete migrating legacy parts too, we can removed the following unregisters.
mixpanel.master.unregister("workspace_id");
mixpanel.master.unregister("project_id");
mixpanel.master.unregister("styleguide_id");
mixpanel.master.unregister("User Role");

mixpanel.master.register({
    "Client": getAppClient(),
    "Client Version": getAppVersion()
});

if (Zeplin.user) {
    const mxShouldAlias = sessionStorage.getItem("mxShouldAlias") || Cookies.get("mxShouldAlias");
    if (mxShouldAlias) {
        sessionStorage.removeItem("mxShouldAlias");
        Cookies.remove("mxShouldAlias");

        mixpanel.master.alias(Zeplin.user._id);
    } else {
        mixpanel.master.identify(Zeplin.user._id);
    }
}

const mxMasterEvent = sessionStorage.getItem("mxMasterEvent") || Cookies.get("mxMasterEvent");
if (mxMasterEvent) {
    sessionStorage.removeItem("mxMasterEvent");
    Cookies.remove("mxMasterEvent");

    let params;
    if (Zeplin.user && Zeplin.user.currentSSOProvider) {
        params = {
            "Authentication Provider": Zeplin.user.currentSSOProvider.type
        };
    }

    mixpanel.master.track(mxMasterEvent, params);
}

/* eslint-disable */
// Twitter universal website tag code
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version="1.1",s.queue=[],u=t.createElement(n),u.async=!0,u.src="//static.ads-twitter.com/uwt.js",
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,"script");
twq("init","nwe8t");
twq("track","PageView");

// Linkedin tracking base code
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push("3692508");
(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);

// Tiktok tracking base code
if (TIKTOK_PIXEL_ID) {
    !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load(TIKTOK_PIXEL_ID);
        ttq.page();
    }(window, document, "ttq");
}
/* eslint-enable */
