import * as Sentry from "@sentry/browser";
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from "../foundation/constants";
import { SENTRY_RELEASE } from "../foundation/mwConstants";

// Ignore URLs
// https://docs.getsentry.com/hosted/clients/javascript/tips/#decluttering-sentry
const sentryOptions = {
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    blacklistUrls: [
        // Stripe
        /\.stripe\.com/i,
        // Intercom
        /widget\.intercom\.io/i,
        /js\.intercomcdn\.com/i,
        // Pusher
        /js\.pusher\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Ignore errors of vendor scripts.
        /vendor-[\w.-]+\.js/i
    ],
    ignoreErrors: [
        // Ignore fetch errors. Requests cancelled by browsers cause fetch to reject.
        // Since it is not possible to tell if it is a cancelled request or a failure we have to ignore them all :(
        /^Failed to fetch/i,
        /^NetworkError when attempting to fetch resource/i,
        /^Network request failed/i,
        /docs-homescreen-gb-container/i,
        /^QuotaExceededError/i,
        /^Can't find variable: (?:loadHomepageTiles|FSOpen|require|_Box_|\$UCBrowser|AlipayJSBridge|auto)/i,
        /__firefox__\.searchQueryForField/i,
        /TypeError: Type error/i,
        /^ResizeObserver loop completed with undelivered notifications/i
    ]
};

Sentry.init(sentryOptions);

if (Zeplin.user) {
    Sentry.configureScope(scope => {
        scope.setUser({
            id: Zeplin.user._id,
            email: Zeplin.user.email,
            username: Zeplin.user.username
        });
    });
} else {
    // Invalidate user context if there is no logged-in user
    Sentry.configureScope(scope => scope.setUser(null));
}
