import Cookies from "../foundation/cookies";

const MS_IN_SEC = 1000; // Milliseconds in a second

// @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value
const SET_TIMEOUT_MAX_DELAY = 2147483647; // Approximately 24.8 days

function handleSessionTokenExpired() {
    const token = JSON.parse(atob(Cookies.get("userToken").split(".")[1]));
    const sessionDurationInMinutes = Math.ceil((token.exp - token.iat) / 60);
    Zeplin.onSessionTokenExpired(sessionDurationInMinutes);
}

let timeout;
function setSessionTokenExpireTimeout() {
    const userToken = Cookies.get("userToken");
    if (!userToken) {
        return;
    }

    const expireTime = JSON.parse(atob(userToken.split(".")[1])).exp;

    if (!expireTime) {
        return;
    }

    const expireTimeFromNow = expireTime * MS_IN_SEC - Date.now();

    if (expireTimeFromNow <= 0) {
        handleSessionTokenExpired();
        return;
    }

    clearTimeout(timeout);
    if (expireTimeFromNow > SET_TIMEOUT_MAX_DELAY) {
        // Timeouts greater than SET_TIMEOUT_MAX_DELAY return immediately due to integer overflow in many browsers.
        // Since SET_TIMEOUT_MAX_DELAY is 24.8 days (much beyond than any reasonable expectation for the browser to stay open), just don't schedule anything at all.
        return;
    }
    timeout = setTimeout(handleSessionTokenExpired, expireTimeFromNow);
}

Zeplin.onSessionTokenExpired = () => Zeplin.logout();
Zeplin.setSessionTokenExpireTimeout = setSessionTokenExpireTimeout;
setSessionTokenExpireTimeout();
