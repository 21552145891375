import { WEB_URL, BASE_URL, IMG_URL } from "../foundation/constants";
import Cookies from "../foundation/cookies";
import { userPrefs } from "../foundation/prefs";
import getRedirectUrl from "../foundation/utils/get-redirect-url";
import { getOS, getDevice } from "../foundation/utils/ua-utils";

const os = getOS();
const device = getDevice();
document.body.classList.add(os);
document.body.classList.add(device);

if (!window.Zeplin) {
    window.Zeplin = {};
}

Zeplin.updateUser = function (data) {
    Object.assign(Zeplin.user, data);
    localStorage.setItem("user", JSON.stringify(Zeplin.user));
};

if (!Zeplin.user) {
    const tmpUser = localStorage.getItem("user");

    if (tmpUser) {
        try {
            window.Zeplin.user = JSON.parse(tmpUser);

            // Migrate old sso object type (It was a single object, and now its the array of the same type)
            if (window.Zeplin.user.sso && !Array.isArray(window.Zeplin.user.sso)) {
                window.Zeplin.user.sso = [window.Zeplin.user.sso];
            }
        } catch (err) {
            localStorage.removeItem("user");
        }
    }
}

Zeplin.urls = Object.freeze({
    baseURL: BASE_URL,
    imgURL: IMG_URL,
    webURL: WEB_URL
});

Zeplin.KEY_CODE = Object.freeze({
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    ALT: 18,
    ESC: 27,
    SPACE: 32,
    END: 35,
    HOME: 36,
    DOT: 190,
    ArrowLeft: 37,
    ArrowUp: 38,
    ArrowRight: 39,
    ArrowDown: 40,
    Digit0: 48,
    KeyA: 65,
    KeyC: 67,
    KeyD: 68,
    KeyE: 69,
    KeyF: 70,
    KeyG: 71,
    KeyJ: 74,
    KeyL: 76,
    KeyR: 82,
    KeyS: 83,
    KeyT: 84,
    KeyV: 86
});

Zeplin.MOUSE_BUTTON = Object.freeze({
    LEFT: 0,
    MIDDLE: 1,
    RIGHT: 2
});

Zeplin.headers = new Headers();
Zeplin.headers.set("Content-Type", "application/json");
if (Cookies.get("userToken")) {
    window.Zeplin.headers.set("Zeplin-Token", Cookies.get("userToken"));
} else if (Cookies.get("mfaToken")) {
    window.Zeplin.headers.set("Zeplin-Token", Cookies.get("mfaToken"));
}

// Process UTM params and save it
const utmParams = {};
location.search.substr(1).split("&").forEach(param => {
    const kv = param.split("=").map(decodeURIComponent);

    const utmParam = kv[0].match(/^utm_(\w+)$/);
    if (utmParam) {
        utmParams[utmParam[1]] = kv[1];
    }
});

if (Object.keys(utmParams).length) {
    Cookies.set("utmParams", JSON.stringify(utmParams));
}

Zeplin.requireLogin = function (redirectTo) {
    if (redirectTo !== false) {
        sessionStorage.setItem("redirectTo", redirectTo || location.href);
    }

    location.replace("/login");
};

Zeplin.logoutWithoutRedirect = function () {
    Cookies.remove("userToken");
    Cookies.remove("mfaToken");
    localStorage.removeItem("user");
    userPrefs.remove("lastAppLocation");
    mixpanel.master.track("Logged out");
};

Zeplin.logout = function () {
    Zeplin.logoutWithoutRedirect();
    Zeplin.requireLogin(false);
};

let redirecting = false;
Zeplin.redirectBack = function () {
    if (!redirecting) {
        redirecting = true;
        const redirectTo = getRedirectUrl(sessionStorage.getItem("redirectTo"), "/projects");

        sessionStorage.removeItem("redirectTo");
        location.replace(redirectTo);
    }
};
